import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import '../styles/Portfolio.css'
import PortfolioObject from './PortfolioObject';

const portfolio = require('../Portfolio.json')

export default function Portfolio() {
    return (
        <div className={'portfolio-root'}>
            <div className={'portfolio-title'}>
                <p className={'portfolio-name'}>Portfolio</p>
            </div>
            <div className={'portfolio-objects'}>
                {
                    portfolio.map((object) => <PortfolioObject object={object} />)
                }
            </div>
        </div>
    )
}