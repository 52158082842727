import React from 'react'
import '../styles/About.css'

export default function About() {
    return (
        <div className='about-root'>
            <div className='about-header'>
                <p className='about-header-title'>About Me</p>
            </div>
            <div className='about-data'>
                <div className='about-text'>
                    <p>
                        I am currently a 21 year old studying computer science at The University of Texas at Dallas.
                        Over the last few years, I've been able to receive the experience of working with different companies
                        in offering services such as dashboards, text bots, support tools, and more!
                    </p>
                    <p>
                        I began my
                        CS journey when I joined university but only got into production development during the pandemic.
                        Starting with Python, I learned through YouTube videos and online forums about the language and
                        eventually branched off into other languages. {new Date().getFullYear() - 2020} years later,
                        I've developed products using a variety of frameworks and tools, ranging from Python's Flask to
                        JavaScript's React and React Native, utilizing public tools like Google's Firebase and Node.js to
                        create in-depth backend services.
                    </p>
                    <p>
                        My passion for computer science and development stems from having the ability to create any of
                        your ideas to fruition. After working on a project and fighting through errors, finally seeing
                        a completed product has a very satisfying feeling that not much else can provide. Solving issues,
                        although frustrating at many times, feels much like a puzzle game, where I am tasked with finding
                        whats wrong and being rewarded with a working product/component of my project.
                    </p>
                    <p>
                        Since developing, I have acquired a strong foundation in various programming languages, such as
                        Python, Java, and JavaScript. I am experienced in a range of tools and frameworks, including React, 
                        Flask, and Node.js, in order to create a variety of dynamic web and mobile applications. I am also
                        well-versed in different tools such as Git, Postman, Firebase and more. 
                    </p>
                </div>
                <div className='about-image'>
                    <img src={process.env.PUBLIC_URL + '/assets/me.png'} />
                </div>
            </div>
        </div>
    )
}