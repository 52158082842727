import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import '../styles/PortfolioObject.css'

function Modal({ images, shown, setShown }) {

    return (
        <div className={['portfolio-object-modal', { display: shown ? 'flex' : 'none' }]}>
            <img className='portfolio-object-image' src='https://cdn.discordapp.com/attachments/579783583376343063/1097636096197795971/IMG_0117.JPEG' />
        </div>
    )
}


export default function PortfolioObject({ object }) {

    const [activeImage, setActiveImage] = useState(0);

    return (
        <div className='portfolio-object-root'>
            <div className='portfolio-object-header'>
                <p className='portfolio-object-title'>{object.title}</p>
            </div>
            <div className='portfolio-object-data'>
                <div className={'portfolio-object-info'}>
                    <p className='portfolio-object-description'>
                        {object.description}
                    </p>
                    <p className='portfolio-object-stack'>Tech Stack: {object.stack}</p>
                    <a href={object.repository} target='_blank' className='portfolio-object-repo' style={{display: object.repository ? "flex" : "none" }}>GitHub Repository</a>
                </div>
                <div className='portfolio-object-images' style={{display: object.images.length > 0 ? "block" : "none"}}>
                    <img className='portfolio-object-image' src={object.images[activeImage]} />
                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                        <p className='portfolio-object-image-swap' onClick={() => {
                            if (activeImage - 1 < 0) {
                                setActiveImage(object.images.length - 1);
                                return;
                            }
                            setActiveImage((activeImage - 1))
                        }}>◀</p>
                        <p>{activeImage + 1}/{object.images.length}</p>
                        <p className='portfolio-object-image-swap' onClick={() => {
                            if (activeImage + 1 === object.images.length) {
                                setActiveImage(0);
                                return;
                            }
                            setActiveImage((activeImage + 1))
                        }}>▶</p>
                    </div>
                </div>
            </div>
        </div>
    )
}