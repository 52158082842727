import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import '../styles/Sidebar.css'
import Home from './Home';
import Portfolio from './Portfolio';
import About from './About';
import Contact from './Contact';
import Experience from './Experience';

function openNav() {
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
}

function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
}

export default function Sidebar({ setPage }) {
    return (
        <div>

            <button onClick={() => openNav()} className='opensidebar'>&#9776;</button>

            <div id="mySidebar" className='sidebar'>
                <a onClick={closeNav} style={{textAlign: "right", paddingRight: 20}}>&times;</a>
                <a onClickCapture={() => {setPage(<Home />); closeNav();}}>Home</a>
                <a onClickCapture={() => {setPage(<Portfolio />); closeNav();}}>Portfolio</a>
                <a onClickCapture={() => {setPage(<Experience />); closeNav();}}>Experience</a>
                <a onClickCapture={() => {setPage(<About />); closeNav();}}>About</a>
                <a onClickCapture={() => {setPage(<Contact />); closeNav();}}>Contact</a>
            </div>
        </div>
    )
}