import React from 'react'
import '../styles/Contact.css'


function deliverMessage() {

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const subject = document.getElementById('subject').value;
    const content = document.getElementById('content').value;

    const message = `
Name: ${name}
Email: ${email}
Subject: ${subject}
Content: ${content === '' ? 'None' : content}`

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "content": message
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch("https://discord.com/api/webhooks/1098172995236798535/8ZDD9Bb_ezBtfNWRYpMylrc6vAndYJGawzdltjppXRbtkizPS9kbTEDnsvBx2YT97BlY", requestOptions)
}

export default function Contact() {
    return (
        <div className='contact-root'>
            <div className='contact-title'>
                <p className='contact-title-primary'>Have any questions?</p>
                <p className='contact-title-secondary'>Ask away here.</p>
            </div>
            <div className='contact-form'>
                <div className='contact-form-object'>
                    <p>Send me a message</p>
                    <form>
                        <input id='name' placeholder='Full Name' />
                        <input id='email' placeholder='Email Address' />
                        <input id='subject' placeholder='Subject' />
                        <p className='contact-form-text'>Tell me more...</p>
                        <textarea id='content' className='contact-form-content' />
                        <button type='button' onClick={() => deliverMessage()}>Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    )
}