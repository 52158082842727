import './App.css';


import Home from './components/Home';
import Sidebar from './components/Sidebar';
import { useState } from 'react';

function App() {

    const [activePage, setActivePage] = useState(<Home />)

    return (
        <div className="App">
            <Sidebar setPage={setActivePage} />
            {activePage}
        </div>
    );
}

export default App;
