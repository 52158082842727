import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import '../styles/Home.css'

const useStyles = makeStyles((theme) => ({
    root: {

    },
    title: {

    },
    name: {
        fontSize: "100px"
    },
    socials: {
    },
    socialimg: {
        height: "50px",
        padding: "10px"
    }
}));
export default function Home() {

    return (
        <div className={'home-root'}>
            <div className={'home-title'}>
                <p className={'home-name'}><span style={{ fontWeight: 200 }}>amaan</span> hussain</p>
                <p className='home-profession'>Full Stack Developer</p>
            </div>
            <div className={'home-socials'}>
                <a href='https://github.com/washedBrownBoy' target='_blank'><img className={'home-socials-img'} src={process.env.PUBLIC_URL + "/assets/github.png"} alt='Github' /></a>
                <a href='https://www.linkedin.com/in/amaan-hussain-4648791b5/' target='_blank'><img className={'home-socials-img'} src={process.env.PUBLIC_URL + "/assets/linkedin.png"} alt='LinkedIn' /></a>
                <a href='https://instagram.com/amaanshussain' target='_blank'><img className={'home-socials-img'} src={process.env.PUBLIC_URL + "/assets/instagram.png"} alt='Instagram' /></a>
            </div>
        </div>
    )
}