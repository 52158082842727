import React from 'react'
import '../styles/Experience.css'

export default function Experience() {

    return (
        <div className='experience-root'>

            <div className='coming-soon'>
                <p>Coming soon...</p>
            </div>

        </div>
    )
}